import { Component, OnInit } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular-legacy";
import {
  ICellRendererParams,
  IAfterGuiAttachedParams,
} from "ag-grid-community";

@Component({
  selector: "delete-component",
  template: '<ion-button style="height:99%; vertical-align: middle;" (click)="buttonClicked()"><ion-icon size="medium" style="font-size: 25px;" name="trash"></ion-icon></ion-button>',
  styleUrls: ["./staffing.page.scss"],
})
export class DeleteRenderer implements AgRendererComponent {
  public gridApi;
  public params;

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.gridApi = params.api;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    throw new Error("Method not implemented.");
  }

  buttonClicked() {
    // Get all the row data from the grid
    let rowData = [];
    this.gridApi.forEachNode(node => rowData.push(node.data));
    // Get the row index of the row that was clicked
    const rowIndex = this.params.node.rowIndex;
    // Remove the row from the row data
    rowData.splice(rowIndex, 1);
    // Update the grid with the new row data
    this.gridApi.setRowData(rowData);
  }
}

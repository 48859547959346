import { Component, OnInit } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular-legacy";
import {
  ICellRendererParams,
  IAfterGuiAttachedParams,
} from "ag-grid-community";
import { Observable } from "rxjs";
import { UtilsService } from "../services/utils.service";
import { SettingsService } from "./settings.service";
import Utils from "../utils";

@Component({
  selector: "app-delete-right",
  templateUrl: "./delete-right.component.html",
})
export class DeleteRight implements AgRendererComponent {
  private toDelete: string;
  public gridApi;
  public params;

  constructor(public utilsService: UtilsService, private settingsService: SettingsService) {}
  public refresh(params: ICellRendererParams): boolean {
    this.toDelete = params.data["group_name"];
    return true;
  }
  public agInit(params: ICellRendererParams): void {
    this.params = params;
    this.gridApi = params.api;
    this.toDelete = params.data["group_name"];
  }
  public afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    throw new Error("Method not implemented.");
  }

  public deleteRight(): Observable<Object> {
    this.gridApi.showLoadingOverlay();
    this.settingsService.delete_group_right(this.toDelete).subscribe(() => {
        // Get all the row data from the grid
        let rowData = [];
        this.gridApi.forEachNode(node => rowData.push(node.data));
        // Get the row index of the row that was clicked
        const rowIndex = this.params.node.rowIndex;
        // Remove the row from the row data
        rowData.splice(rowIndex, 1);
        // Update the grid with the new row data
        this.gridApi.setRowData(rowData);
        this.gridApi.hideOverlay();
      });
    return;
  }
}

import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-duplicate-imputation-modal",
  templateUrl: "./duplicate-imputation-modal.page.html",
  styleUrls: ["./duplicate-imputation-modal.page.scss"],
})
export class DuplicateImputationModalPage {
  public nbrMonth: string;

  constructor(private modalController: ModalController) {
    this.nbrMonth = "0";
  }

  public async closeModal(quit: boolean) {
    if (quit) await this.modalController.dismiss(this.nbrMonth);
    else await this.modalController.dismiss();
  }

  numberOnlyValidation(event: any) {
    const pattern = /[0-9.,]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  public transformMonthToNumber() {
    return parseInt(this.nbrMonth);
  }
}

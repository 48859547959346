import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

import { HttpClientModule } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { EventsService } from "./services/events.service";

import { FilterComponent } from "./filter/filter.component";
import { AgGridModule } from "ag-grid-angular-legacy";
import { ModalPageModule } from "./modal/modal.module";
import { DuplicateImputationModalPageModule } from "./duplicate-imputation-modal/duplicate-imputation-modal.module";
import { IonicSelectableModule } from "ionic-selectable";
import { DigitalLearningComponent } from "./digital-learning/digital-learning.component";
import { CvLinkComponent } from "./cv-link/cv-link.component";
import { TjmRenderComponent } from "./tjm-render/tjm-render.component";
import { ConfirmationModalPageModule } from "./confirmation-modal/confirmation-modal.module";
import { ModalService } from "./services/modal.service";
import { BoondLinkComponent } from "./boond-link/boond-link.component";
import { TooltipComponent } from './tooltip/tooltip.component';
import { DeleteRight } from "./settings/delete-right.component";
import { DeleteRenderer } from "./staffing/delete-renderer.component";

import { OAuthModule } from "angular-oauth2-oidc";

@NgModule({
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    AgGridModule,
    ModalPageModule,
    ConfirmationModalPageModule,
    DuplicateImputationModalPageModule,
    IonicSelectableModule,
    BrowserAnimationsModule,
    OAuthModule.forRoot(),
  ],
  declarations: [
    AppComponent,
    FilterComponent,
    DigitalLearningComponent,
    CvLinkComponent,
    TjmRenderComponent,
    DeleteRight,
    BoondLinkComponent,
    TooltipComponent,
    DeleteRenderer
  ],
  providers: [
    StatusBar,
    SplashScreen,
    ModalService,
    CookieService,
    EventsService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }

import { HttpClient } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router, RouterStateSnapshot } from "@angular/router";
import { HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { AuthConfig, OAuthService } from "angular-oauth2-oidc";

const QUERY_URL: string = environment.onestaffing_apiurl;

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private redirecto: string;
  private loggedIn = new BehaviorSubject<boolean>(false);
  private loginError = new BehaviorSubject<boolean | null>(null);

  authConfig: AuthConfig = {
    issuer: 'https://accounts.google.com',
    redirectUri: window.location.origin,
    clientId: environment.google_client_id,
    scope: 'openid profile email',
    strictDiscoveryDocumentValidation: false
  };

  constructor(
    private http: HttpClient,
    public router: Router,
    private ngZone: NgZone,
    private oauthService: OAuthService,

  ) {
    this.oauthService.configure(this.authConfig);
    this.oauthService.setStorage(localStorage);
  }

  public initLogin(): void {
    this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
      this.checkAndUpdateLoginStatus(); // Update the login status
      if (this.oauthService.hasValidAccessToken()) {
        this.setupAutoRefreshToken();
        this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            if (event.url.split("/")[1] === "" || event.url.split("/")[1] === "sign-in") {
              if (!this.redirecto) {
                if (sessionStorage.getItem('redirecto'))
                  this.router.navigate([sessionStorage.getItem('redirecto')]);
                else
                  this.router.navigate(["/staffing"]);
                sessionStorage.removeItem('redirecto');
              } else {
                this.router.navigate([this.redirecto]);
              }
            }
          }
        });
      } else {
        this.initLoginFlowConditionally();
      }
    }).catch((error) => {
      console.log(error)
      this.handleLoginError(true);
    });
  }

  private handleLoginError(error: boolean): void {
    this.loginError.next(error);
    this.ngZone.run(() => this.router.navigate(['/']));
  }

  public get loginError$(): Observable<boolean | null> {
    return this.loginError.asObservable();
  }

  private initLoginFlowConditionally(): void {
    if (this.router.url.split("/")[1] !== "" && this.router.url.split("/")[1] !== "sign-in") {
      this.initLoginFlow();
    }
  }

  private initLoginFlow(): void {
    this.oauthService.initImplicitFlow();
  }

  private setupAutoRefreshToken(): void {
    this.oauthService.setupAutomaticSilentRefresh();
  }

  public login(url?: string): void {
    sessionStorage.setItem('redirecto', this.redirecto ? this.redirecto : '/staffing');
    this.oauthService.initImplicitFlow();
  }

  public logout(): void {
    this.oauthService.logOut();
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  public get identityClaims() {
    return this.oauthService.getIdentityClaims();
  }

  getUserPicture(): any {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    return claims['picture'];
  }

  getUserEmail(): any {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    return claims['email'];
  }

  getUserName(): any {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    return claims['name'];
  }

  getAccessToken(): string {
    return this.oauthService.getAccessToken();
  }

  getIdToken(): string {
    return this.oauthService.getIdToken();
  }

  public get isLoggedIn(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  private updateLoginStatus(): void {
    const isUserLoggedIn = this.oauthService.hasValidAccessToken();
    this.loggedIn.next(isUserLoggedIn);
  }

  public checkAndUpdateLoginStatus(): void {
    this.updateLoginStatus();
  }

  public checkSilentLogin(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      if (this.oauthService.hasValidAccessToken()) {
        observer.next(true);
      } else {
        // If there's no valid access token, try to silently refresh it
        this.oauthService.silentRefresh().then(() => {
          observer.next(this.oauthService.hasValidAccessToken());
        }).catch(() => {
          observer.next(false);
        });
      }
    });
  }

  public refreshToken() {
    this.oauthService.refreshToken()
  }

  /**
   * Ask our api if the token is valid
   */
  isAuthenticated(url) {
    var requestOptions = {
      headers: new HttpHeaders().set('Authorization', this.getIdToken())
    };

    var requestBody = {
      actualPage: url.split("/")[1]
    }

    return this.http.post(QUERY_URL + "/verify_token", requestBody, requestOptions);
  }

  /**
   * Ask our api which route the user can access
   */
  getAuthorizedPath() {
    var requestOptions = {
      headers: new HttpHeaders().set('Authorization', this.getIdToken())
    };

    var requestBody = {
      actualPage: window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ] == ""
        ? "sign-in"
        : window.location.href.split("/")[
        window.location.href.split("/").length - 1
        ],
    }

    return this.http.post(QUERY_URL + "/get_authorized_path", requestBody, requestOptions);
  }

  setredirecto(url) {
    this.redirecto = url;
  }
}

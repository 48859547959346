<div>
  <div class="main-card">
    <div class="title">
      <ion-button
        (click)="changeSelection()"
        class="back-button"
        expand="block"
        fill="outline"
      >
        Tout cocher/Tout décocher
      </ion-button>
    </div>

    <div class="field"></div>
    <ion-item *ngFor="let categorie of categories">
      <ion-label>{{ categorie.field }}</ion-label>
      <ion-checkbox
        [checked]="categorie.active"
        (click)="onChange(categorie.field, !categorie.active)"
        color="primary"
      ></ion-checkbox>
    </ion-item>
  </div>
</div>

import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";

@Component({
  selector: "app-confirmation-modal",
  templateUrl: "./confirmation-modal.page.html",
  styleUrls: ["./confirmation-modal.page.scss"],
})
export class ConfirmationModalPage implements OnInit {
  public text: string;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams
  ) {}

  ngOnInit() {
    this.text = this.navParams.data.text;
  }

  public async closeModal(quit: boolean) {
    await this.modalController.dismiss(quit);
  }
}

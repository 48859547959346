<ion-app *ngIf="isLoggedIn || isLoginPage">
  <ion-menu *ngIf="showSideBar" side="start" menuId="first" contentId="content1">
    <ion-header>
      <ion-toolbar>
        <ion-title>Menu</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content *ngIf="showSideBar">
      <ion-list *ngFor="let pages of routes">
        <ion-menu-toggle auto-hide="true">
          <ion-item [routerLink]="pages.path" routerDirection="forward">
            <ion-icon [name]="pages.icon" slot="start"></ion-icon>
            {{ pages.title }}
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="content1"></ion-router-outlet>
</ion-app>
<div class="fixed inset-0 bg-gray-700 opacity-50 pointer-events-none" *ngIf="!isLoggedIn && !isLoginPage"></div>
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./sign-in/sign-in.module').then( m => m.SignInPageModule)
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./sign-in/sign-in.module').then( m => m.SignInPageModule)
  },
  {
    path: 'sign-in/:redirecto',
    loadChildren: () => import('./sign-in/sign-in.module').then( m => m.SignInPageModule)
  },
  {
    path: 'staffing',
    loadChildren: () => import('./staffing/staffing.module').then( m => m.HomePageModule)
  },
  {
    path: 'reporting-velvet',
    loadChildren: () => import('./reporting-velvet/reporting-velvet.module').then( m => m.ReportingVelvetModule)
  },
  {
    path: 'tjm-par-grade-pole',
    loadChildren: () => import('./tjm-par-grade-pole/tjm-par-grade-pole.module').then( m => m.TjmParGradePoleModule)
  },
  {
    path: 'confirmation-modal',
    loadChildren: () => import('./confirmation-modal/confirmation-modal.module').then( m => m.ConfirmationModalPageModule)
  },
  {
    path: 'humeur-et-details-cra',
    loadChildren: () => import('./humeur-et-details-cra/humeur-et-details-cra.module').then( m => m.HumeurEtDetailsCraModule)
  },
  {
    path: 'activite-par-grade-pole',
    loadChildren: () => import('./activite-par-grade-pole/activite-par-grade-pole.module').then( m => m.ActiviteParGradePoleModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'velvet-jours-de-prod',
    loadChildren: () => import('./velvet-jours-de-prod/velvet-jours-de-prod.module').then( m => m.VelvetJoursDeProdModule)
  },
  {
    path: 'velvet-tjm',
    loadChildren: () => import('./velvet-tjm/velvet-tjm.module').then( m => m.VelvetTjmModule)
  },
  {
    path: 'crud-ressources-histo',
    loadChildren: () => import('./crud-ressources-histo/crud-ressources-histo.module').then( m => m.CrudRessourcesHistoModule)
  },
  {
    path: 'looker-onebusiness',
    loadChildren: () => import('./looker-onebusiness/looker-onebusiness.module').then(m => m.LookerOneBusinessDashboardModule)
  },
  {
    path: 'looker-onestaffing',
    loadChildren: () => import('./looker-onestaffing/looker-onestaffing.module').then(m => m.LookerOneStaffingDashboardModule)
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

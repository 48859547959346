import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";

class Client {
  public name: string;
  public text: string;
}

@Component({
  selector: "app-modal",
  templateUrl: "./modal.page.html",
  styleUrls: ["./modal.page.scss"],
})
export class ModalPage implements OnInit {
  public clients: Client[];
  public client: Client;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams
  ) {}

  ngOnInit() {
    this.clients = this.sortClients(this.navParams.data.clients);
    // Iterate in clients and set upperCase to text
    for (let i = 0; i < this.clients.length; i++) {
      this.clients[i].text = this.clients[i].name;
    }
  }

  async closeModal(cancel: boolean): Promise<void> {
    if (cancel) await this.modalController.dismiss();
    else await this.modalController.dismiss(this.client);
  }

  sortClients(clients: Client[]): Client[] {
    // Iterate in Clients and delete client with empty name
    for (let i = 0; i < clients.length; i++) {
      if (clients[i].name === "") {
        clients.splice(i, 1);
      }
    }
    // Sort alphabetically by name
    return clients.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}

import { Component } from "@angular/core";
import { ICellRendererParams } from "ag-grid-community";
import { AgRendererComponent } from "ag-grid-angular-legacy";

@Component({
  selector: "app-digital-learning",
  templateUrl: "./digital-learning.component.html",
  styleUrls: ["./digital-learning.component.scss"],
})
export class DigitalLearningComponent implements AgRendererComponent {
  public cellValue: string;
  public comp: string;

  // gets called once before the renderer is used
  public agInit(params: ICellRendererParams): void {
    if (params.data["type"] != "Sous-traitant")
      this.comp = params.data["Collaborateur"];
    else
      this.comp = null;
    this.cellValue = this.getValueToDisplay(params);
  }

  // gets called whenever the user gets the cell to refresh
  public refresh(params: ICellRendererParams) {
    // set value into cell again
    this.cellValue = this.getValueToDisplay(params);
    this.comp = params.data["Collaborateur"];
    return true;
  }

  public getValueToDisplay(params: ICellRendererParams) {
    return params.valueFormatted ? params.valueFormatted : (params.value || '').toUpperCase();
  }
}

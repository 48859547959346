<ion-content class="ion-padding">
  <ion-grid>
    <ion-row class="ion-justify-content-center">
      <ion-col size="6">
        <ion-item class="select-client">
          <ion-label *ngIf="client == null">Sélectionner un client</ion-label>
          <ionic-selectable
            [(ngModel)]="client"
            [items]="clients"
            itemValueField="name"
            itemTextField="text"
            [canSearch]="true"
            class="select"
            [shouldFocusSearchbar]="true">
          >
          </ionic-selectable>
        </ion-item>
      </ion-col>
      <ion-col size="2" class="confirmation-col">
        <ion-button (click)="closeModal(false)">Confirmer</ion-button>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center">
      <ion-col size="3">
        <ion-button
          color="medium"
          class="new-client"
          (click)="client={name:'default', text:'default'};closeModal(false)"
          >Créer un client non listé</ion-button
        >
      </ion-col>
      <ion-col size="3">
        <ion-button color="medium" (click)="closeModal(true)"
          >Annuler</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular-legacy';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-tjm-render',
  templateUrl: './tjm-render.component.html',
  styleUrls: ['./tjm-render.component.scss'],
})
export class TjmRenderComponent implements AgRendererComponent {
  public cellValue;

  // gets called once before the renderer is used
  public agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
  }

  // gets called whenever the user gets the cell to refresh
  public refresh(params: ICellRendererParams): boolean {
    // set value into cell again
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  private getValueToDisplay(params: ICellRendererParams): string {
    return params.valueFormatted ? params.valueFormatted : params.value;
  }
}

import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { ToastController } from "@ionic/angular";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    public toastController: ToastController
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.isAuthenticated(state.url).pipe(
      map((response: { authenticated: boolean }) => {
        if (response.authenticated) {
          return true;
        }
        this.router.navigate(["/sign-in"]);
        return false;
      }),
      catchError((error) => {
        // If response code is 401, then user is not authenticated
        if (error.status === 401) {
          alert(
            "Vous ne bénéficiez pas des accès à One Staffing. Si vous souhaitez y accéder, merci de faire un ticket via https://forms.gle/aMcKQyWZSu986Ukv9"
          );
          this.router.navigate(["/sign-in", state.url]);
          return of(false);
        } else if (error.status === 402) {
          alert("Veuillez vous connecter avant d'accéder à l'application.");
          this.router.navigate(["/sign-in", state.url]);
          return of(false);
        } else if (error.status === 400) {
          console.log(error);
          this.authService.refreshToken();
        } else {
          this.router.navigate(["/sign-in", state.url]);
          return of(false);
        }
      })
    );
  }
}

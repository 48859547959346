import { Injectable } from "@angular/core";
import {
  AlertController,
  LoadingController,
  ToastController,
} from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  private loader: any;

  constructor(
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController
  ) {}

  /*
   * @params {string} message
   * Show loader
   */
  public async showLoader(message: string) {
    if (this.loader) {
      return;
    }
    this.loader = await this.loadingCtrl.create({
      message,
      cssClass: "spinner",
      spinner: "bubbles",
    });
    await this.loader.present();
  }

  /*
   * @params {string} message
   * Update loader
   */
  public async updateLoader(message: string) {
    if (this.loader) {
      this.loader.message = message;
    }
  }

  /*
   * Dismiss loader
   */
  public async dismissLoader() {
    if (this.loader) {
      try {
        await this.loader.dismiss();
      } catch (e) {
      } finally {
        this.loader = null;
      }
    }
  }

  /*
   * @params {any} config
   * Show alert
   */
  public async showAlert(config: any) {
    const alert = await this.alertCtrl.create(config);
    await this.dismissLoader();
    await alert.present();
    await alert.onWillDismiss();
  }

  /*
   * @params {string} message
   * Show Toast
   */
  public async showToastTop(message: string, color: string) {
    const toast = await this.toastCtrl.create({
      message,
      position: "top",
      color,
      duration: 3000,
    });
    toast.present();
  }

  /*
   * @params {string} message
   * Show Toast
   */
  public async showToastBottom(message: string, color: string) {
    const toast = await this.toastCtrl.create({
      message,
      position: "bottom",
      color,
      duration: 3000,
    });
    toast.present();
  }
}

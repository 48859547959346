import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ModalPage } from './modal.page';
import { ProfilePageResolver } from '../user/user.resolver';
import { TopBarModule } from '../top-bar/top-bar.module';
import { AgGridModule } from 'ag-grid-angular-legacy';
import { RouterModule, Routes } from '@angular/router';
import { IonicSelectableModule } from 'ionic-selectable';
import { AuthGuard } from '../auth/auth.guard';


const routes: Routes = [
  {
    path: '',
    component: ModalPage,
    canActivate: [AuthGuard],
    resolve: {
      data: ProfilePageResolver
    }
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    AgGridModule,
    TopBarModule,
    IonicSelectableModule
  ],
  declarations: [ModalPage],
  providers: [ProfilePageResolver]
})
export class ModalPageModule {}

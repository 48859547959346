import { Injectable } from "@angular/core";
import { Subject, Subscription } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EventsService {
  private fooSubject = new Subject<any>();

  private channels: { [key: string]: Subject<any> } = {};
  constructor() {}

  public publishSomeData(data: any) {
    this.fooSubject.next(data);
  }

  public getObservable(): Subject<any> {
    return this.fooSubject;
  }
  /*
   * Subscribe to a topic and provide a single handler/observer.
   * @param {string} topic The name of the topic to subscribe to.
   * @param {any} observer The observer or callback function to listen when changes are published.
   *
   * @returns {Subscription} from which you can unsubscribe to release memory resources and to prevent memory leak.
   */
  public subscribe(topic: string, observer: (_: any) => void): Subscription {
    if (!this.channels[topic]) {
      // You can also use ReplaySubject with one concequence
      this.channels[topic] = new Subject<any>();
    }
    return this.channels[topic].subscribe(observer);
  }

  /*
   * Publish some data to the subscribers of the given topic.
   * @param {string} topic The name of the topic to emit data to.
   * @param {any} data data in any format to pass on.
   */
  public async publish(topic: string, data?: any) {
    if (!this.channels[topic]) {
      this.channels[topic] = new Subject<any>();
    }

    //the next not working without the timeout 'bug'
    setTimeout(() => this.channels[topic].next(data), 0);
  }

  /*
   * destroy the observable of the topic using this method.
   * @param {string} topic The name of the topic to destroy.
   */
  public destroy(topic: string): null {
    const subject = this.channels[topic];
    if (!subject) {
      return;
    }

    subject.complete();
    delete this.channels[topic];
  }
}

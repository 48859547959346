import { Component, OnInit } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular-legacy";
import {
  ICellRendererParams,
  IAfterGuiAttachedParams,
} from "ag-grid-community";

@Component({
  selector: "app-boond-link",
  templateUrl: "./boond-link.component.html",
  styleUrls: ["./boond-link.component.scss"],
})
export class BoondLinkComponent implements AgRendererComponent {
  public cellValue;
  public comp;

  constructor() {}
  refresh(params: ICellRendererParams): boolean {
    this.cellValue = this.getValueToDisplay(params);
    this.comp = params.data["Collaborateur"];
    return true;
  }

  agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
    this.comp = params.data["Collaborateur"];
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    throw new Error("Method not implemented.");
  }

  getValueToDisplay(params: ICellRendererParams) {
    return params.valueFormatted ? params.valueFormatted : params.value;
  }
}

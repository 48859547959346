<ion-content>
  <ion-grid>
    <ion-row class="ion-margin">
      <ion-col>
        <ion-text> {{text}} </ion-text>
      </ion-col>
    </ion-row>
    <ion-row class="ion-margin">
      <ion-col class="ion-text-right">
        <ion-button color="medium" (click)="closeModal(undefined)"
          >Revenir à l'édition</ion-button
        >
      </ion-col>
      <ion-col class="ion-text-left">
        <ion-button color="medium" (click)="closeModal(true)"
          >Annuler la modification</ion-button
        >
      </ion-col>
    </ion-row>
    <ion-row class="ion-margin">
      <ion-col>
        <ion-button (click)="closeModal(false)"
          >Enregistrer la modification</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

import { Component } from "@angular/core";
import { IDoesFilterPassParams, IFilterParams } from "ag-grid-community";
import { AgFilterComponent } from "ag-grid-angular-legacy";
import { cloneDeep } from "lodash";

@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"],
})
export class FilterComponent implements AgFilterComponent {
  private params: IFilterParams;
  public categories = [];
  private header: string;
  private table_data = [];

  public agInit(params: IFilterParams) {
    this.categories = params.colDef.filterParams.filters;
    this.header = params.colDef.filterParams.header;
    this.params = params;
    this.params.filterChangedCallback();
  }

  public isFilterActive(): boolean {
    for (let i = 0; i < this.categories.length; i++)
      if (!this.categories[i].active) return true;
    return false;
  }

  public doesFilterPass(params: IDoesFilterPassParams): boolean {
    if (this.header === " ") {
      this.getTable();
      const columnVisible = "Jours ouvrables du mois";
      return (
        params.data["data"].includes(columnVisible) ||
        this.table_data.some(
          (tableData) =>
            parseInt(params.node.id) === tableData.id &&
            this.categories.some(
              (category) => category.field === tableData.bu && category.active
            )
        )
      );
    } else {
      const validCategories = this.categories
        .filter((category) => category.active)
        .map((category) => category.field.toLowerCase());
      const headerValue = params.data[this.header];
      return (
        headerValue === "" ||
        headerValue === null ||
        validCategories.includes(headerValue.toLowerCase())
      );
    }
  }

  public getTable(): void {
    this.table_data = [];
    var last_bu = "";
    var valid = false;
    this.params.rowModel.forEachNode((node, index) => {
      var new_node = cloneDeep(node.data);
      new_node.id = index;
      for (let i = 0; i < this.categories.length; i++) {
        if (
          this.categories[i].field.includes(new_node.data) &&
          !new_node.data.includes("KPI") &&
          last_bu != this.categories[i].field
        ) {
          new_node.bu = this.categories[i].field;
          last_bu = this.categories[i].field;
          valid = this.categories[i].active;
        }
      }
      new_node.bu = last_bu;
      if (valid) this.table_data.push(new_node);
    });
  }

  /*
   * This method return the model
   */
  public getModel(): object {
    let valid = [];
    for (let i = 0; i < this.categories.length; i++)
      if (this.categories[i].active) valid.push(this.categories[i]);
    return { value: valid };
  }

  /*
   * This method set the model
   */
  public setModel(model: any): void {
    // Iterate through the categories and set the active property
    if (model && model.length > 0) {
      this.categories = model;
      this.params.filterChangedCallback();
    } else if (model != null) {
      for (let i = 0; i < this.categories.length; i++)
        this.categories[i].active = true;
      this.params.filterChangedCallback();
    }
  }

  /*
   * This method is called when the user makes a change to the filter
   */
  public onChange(field: string, active: boolean): void {
    // Find the category that was selected
    for (let i = 0; i < this.categories.length; i++)
      if (this.categories[i].field === field)
        this.categories[i] = {
          field: field,
          active: active,
          id: this.categories[i].id,
        };
    this.params.filterChangedCallback();
  }

  /*
   * This method is called when the user click on "Tout cocher/Tout décocher"
   */
  public changeSelection(): void {
    if (
      this.categories.filter((x) => x.active == false || x.active == null)
        .length > 0
    )
      this.selectAll();
    else if (this.categories.filter((x) => x.active == true).length > 0)
      this.deselectAll();
  }

  /*
   * Set all categories to active
   */
  private selectAll(): void {
    // Find the category that was selected
    for (let i = 0; i < this.categories.length; i++)
      this.categories[i].active = true;
    this.params.filterChangedCallback();
  }

  /*
   * Set all categories to inactive
   */
  private deselectAll(): void {
    // Find the category that was selected
    for (let i = 0; i < this.categories.length; i++)
      this.categories[i].active = false;
    this.params.filterChangedCallback();
  }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/auth.service";

const QUERY_URL: string = environment.onestaffing_apiurl;

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  public grade: any;
  public pole: any;
  public bu: any;
  public billableDays: any;

  private token: string = this.authService.getIdToken();
  private headers: HttpHeaders = new HttpHeaders({ 'Authorization': this.token });

  constructor(
    private http: HttpClient,
    public authService: AuthService
  ) { }

  public get_grade(): Observable<Object> {
    var requestBody = {
      actualPage: window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ] == ""
        ? "sign-in"
        : window.location.href.split("/")[
        window.location.href.split("/").length - 1
        ],
    }

    return this.http.post(QUERY_URL + "/get_grade", requestBody, { headers: this.headers });
  }

  public get_pole(): Observable<Object> {

    var requestBody = {
      actualPage: window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ] == ""
        ? "sign-in"
        : window.location.href.split("/")[
        window.location.href.split("/").length - 1
        ],
    };

    return this.http.post(QUERY_URL + "/get_pole", requestBody, { headers: this.headers });
  }

  public get_bu(): Observable<Object> {
    var requestBody = {
      actualPage: window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ] == ""
        ? "sign-in"
        : window.location.href.split("/")[
        window.location.href.split("/").length - 1
        ],
    };

    return this.http.post(QUERY_URL + "/get_bu", requestBody, { headers: this.headers });
  }

  public get_billable_days(): Observable<Object> {
    { headers: this.headers } var requestBody = {
      actualPage: window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ] == ""
        ? "sign-in"
        : window.location.href.split("/")[
        window.location.href.split("/").length - 1
        ],
    };

    return this.http.post(QUERY_URL + "/get_billable_days", requestBody, { headers: this.headers });
  }

  public get_pole_with_bu_id(): Observable<Object> {
    var requestBody = {
      actualPage: window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ] == ""
        ? "sign-in"
        : window.location.href.split("/")[
        window.location.href.split("/").length - 1
        ],
    };

    return this.http.post(QUERY_URL + "/get_pole_with_bu_id", requestBody, { headers: this.headers });
  }

  public check_comment(piste_or_comment, startDate, referenceInterne): Observable<Object> {
    var requestBody = {
      piste_or_comment: piste_or_comment,
      startTerm: startDate,
      referenceInterne: referenceInterne,
      actualPage: window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ] == ""
        ? "sign-in"
        : window.location.href.split("/")[
        window.location.href.split("/").length - 1
        ],
    }

    return this.http.post(QUERY_URL + "/check_comment", requestBody, { headers: this.headers });
  }

  public update_comment(pisteOrComment, managerOrRh, startDate, newComment, referenceInterne): Observable<Object> {
    var requestBody = {
      piste_or_comment: pisteOrComment,
      manager_or_rh: managerOrRh,
      startTerm: startDate,
      newComment: newComment,
      referenceInterne: referenceInterne,
      actualPage: window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ] == ""
        ? "sign-in"
        : window.location.href.split("/")[
        window.location.href.split("/").length - 1
        ],
    }

    return this.http.post(QUERY_URL + "/update_comment", requestBody, { headers: this.headers });
  }

  public insert_comment(pisteOrComment, managerOrRh, startDate, newComment, referenceInterne): Observable<Object> {
    var requestBody = {
      piste_or_comment: pisteOrComment,
      manager_or_rh: managerOrRh,
      startTerm: startDate,
      newComment: newComment,
      referenceInterne: referenceInterne,
      actualPage: window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ] == ""
        ? "sign-in"
        : window.location.href.split("/")[
        window.location.href.split("/").length - 1
        ],
    }

    return this.http.post(QUERY_URL + "/insert_comment", requestBody, { headers: this.headers });
  }

  public onBtExport(gridApi, filename) {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: filename,
    };
    gridApi.exportDataAsCsv(params);
  }
}

<ion-content class="ion-padding">
  <ion-grid>
    <ion-row class="ion-justify-content-center">
      <ion-col text-center size="auto" with="100%">
        <ion-label
            >Vous êtes sur le point d'enregistrer un temps plein sur ce mois.
            Souhaitez-vous faire prolonger cette saisie sur plusieurs mois ? Si
            oui, merci d'indiquer le nombre de mois supplémentaires
            souhaités</ion-label>
        <ion-item>
          <ion-input
            required
            [(ngModel)]="nbrMonth"
            placeholder="0"
            (keypress)="numberOnlyValidation($event)"
          ></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center">
      <ion-col text-center size="auto">
        <ion-button (click)="closeModal(true)" [disabled]="transformMonthToNumber() > 12">Enregistrer</ion-button>
      </ion-col>
      <ion-col text-center size="auto">
        <ion-button color="medium" (click)="closeModal(false)"
          >Annuler</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

import { Component } from "@angular/core";
import { ICellRendererParams } from "ag-grid-community";
import { AgRendererComponent } from "ag-grid-angular-legacy";

@Component({
  selector: "app-cv-link",
  templateUrl: "./cv-link.component.html",
  styleUrls: ["./cv-link.component.scss"],
})
export class CvLinkComponent implements AgRendererComponent {
  public cellValue: string;
  public cellLink: string;

  // gets called once before the renderer is used
  public agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
    // transform the url in valid link
    if (this.cellValue != null) {
      if (this.cellValue.includes("http://") || this.cellValue.includes("https://")) {
        this.cellLink = this.cellValue;
      } else {
        this.cellLink = "http://" + this.cellValue;
      }
    }
  }

  // gets called whenever the user gets the cell to refresh
  public refresh(params: ICellRendererParams) {
    // set value into cell again
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  public getValueToDisplay(params: ICellRendererParams) {
    return params.valueFormatted ? params.valueFormatted : (params.value || '');
  }
}

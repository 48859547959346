<ion-toolbar>
  <ion-buttons slot="start">
    <ion-menu-button></ion-menu-button>
  </ion-buttons>
  <ion-item button slot="start" (click)="goToStaffing()" title="" lines="none" style="padding-top: 0.5vw;">
    <ion-img src="../../assets/logo_v2.png" style="width: 200px;"></ion-img>
  </ion-item>

  <ion-title class="ion-text-center"> {{ title }} </ion-title>
  <ion-buttons slot="end" title="">
    {{ user.name }}
    <ion-button title="">
      <img class="user-image" [src]="user.photo" referrerpolicy="no-referrer" />
    </ion-button>
  </ion-buttons>
  <ion-button
    slot="end"
    fill="clear"
    color="medium"
    (click)="goToSettings()"
    title="Settings"
  >
    <ion-icon src="../../assets/icon/settings.svg"></ion-icon>
  </ion-button>
  <ion-button
    slot="end"
    fill="clear"
    color="danger"
    (click)="signOut()"
    title="Se déconnecter"
  >
    <ion-icon name="log-out"></ion-icon>
  </ion-button>
</ion-toolbar>

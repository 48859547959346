import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/auth.service";

const QUERY_URL: string = environment.onestaffing_apiurl;

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  public grade: any;
  public pole: any;
  public bu: any;
  public billableDays: any;

  private token: string = this.authService.getIdToken();
  private headers: HttpHeaders = new HttpHeaders({ 'Authorization': this.token });

  constructor(
    private http: HttpClient,
    public authService: AuthService
  ) { }

  public ressources_rights(): Observable<Object> {
    var requestBody = {
      actualPage: window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ] == ""
        ? "sign-in"
        : window.location.href.split("/")[
        window.location.href.split("/").length - 1
        ],
    }

    return this.http.post(QUERY_URL + "/ressources_rights", requestBody, { headers: this.headers });
  }

  public new_group_right(groupe_name, routesSQL, gradesSQL, emailsSQL): Observable<Object> {
    var requestBody = {
      groupeName: groupe_name,
      routesAuthorized: routesSQL,
      gradesAuthorized: gradesSQL,
      emailsAuthorized: emailsSQL,
      actualPage: window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ] == ""
        ? "sign-in"
        : window.location.href.split("/")[
        window.location.href.split("/").length - 1
        ],
    }

    return this.http.post(QUERY_URL + "/new_group_right", requestBody, { headers: this.headers });
  }

  public update_group_right(path_or_grade_or_email, newValue, groupName): Observable<Object> {
    var requestBody = {
      path_or_grade_or_email: path_or_grade_or_email,
      new_value: newValue,
      group_name: groupName,
      actualPage: window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ] == ""
        ? "sign-in"
        : window.location.href.split("/")[
        window.location.href.split("/").length - 1
        ],
    }

    return this.http.post(QUERY_URL + "/update_group_right", requestBody, { headers: this.headers });
  }

  public rename_group_right(old_group_name, new_group_name): Observable<Object> {
    var requestBody = {
      old_group_name: old_group_name,
      new_group_name: new_group_name,
      actualPage: window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ] == ""
        ? "sign-in"
        : window.location.href.split("/")[
        window.location.href.split("/").length - 1
        ],
    }

    return this.http.post(QUERY_URL + "/rename_group_right", requestBody, { headers: this.headers });
  }

  public delete_group_right(toDelete): Observable<Object> {
    var requestBody = {
      groupToDelete: toDelete,
      actualPage: window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ] == ""
        ? "sign-in"
        : window.location.href.split("/")[
        window.location.href.split("/").length - 1
        ],
    }

    return this.http.post(QUERY_URL + "/delete_group_right", requestBody, { headers: this.headers });
  }

  public get_group_right(): Observable<Object> {
    var requestBody = {
      actualPage: window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ] == ""
        ? "sign-in"
        : window.location.href.split("/")[
        window.location.href.split("/").length - 1
        ],
    }

    return this.http.post(QUERY_URL + "/get_group_right", requestBody, { headers: this.headers });
  }
}

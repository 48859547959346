import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../auth/auth.service";
import { EventsService } from "../services/events.service";

@Component({
  selector: "app-top-bar",
  templateUrl: "./top-bar.component.html",
  styleUrls: ["./top-bar.component.scss"],
})
export class TopBarComponent implements OnInit {
  public user;

  @Input()
  public title: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public events: EventsService
  ) { }

  public ngOnInit(): void {
    this.route.data.subscribe(
      (result) => {
        this.user = result["data"];
      },
      (err) => { }
    );
    this.events.publish("load::path", "yes");
  }

  public signOut(): void {
    this.authService.logout();
    this.router.navigate(["sign-in"]);
  }

  public goToSettings(): void {
    this.router.navigate(["settings"]);
  }

  public goToStaffing(): void {
    this.router.navigate(["/staffing"]);
  }
}

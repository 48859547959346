import { Component } from "@angular/core";
import { AlertController, Platform } from "@ionic/angular";
import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { AuthService } from "./auth/auth.service";
import { EventsService } from "./services/events.service";
import { CookieService } from "ngx-cookie-service";
import { NavigationEnd, Router } from "@angular/router";
import { Location } from "@angular/common";
import { cloneDeep } from "lodash";
import { Subscription } from "rxjs";

export let cookieConsent = "no";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent {
  routes = [
    {
      path: "staffing",
      title: "Staffing", 
      icon: "people-outline",
    },
    {
      path: "activite-par-grade-pole",
      title: "Activité par grade et pôle",
      icon: "calendar-number-outline",
    },
    {
      path: "tjm-par-grade-pole",
      title: "TJM par grade et pôle",
      icon: "cash-outline",
    },
    {
      path: "reporting-velvet",
      title: "Velvet KPIs",
      icon: "stats-chart-outline",
    },
    {
      path: "velvet-jours-de-prod",
      title: "Velvet - Jours de prod",
      icon: "calendar-number-outline",
    },
    {
      path: "velvet-tjm",
      title: "Velvet - TJM",
      icon: "cash-outline",
    },
    {
      path: "crud-ressources-histo",
      title: "Gestion des Consultants : Grade/Pole",
      icon: "people-outline",
    },
    {
      path: "humeur-et-details-cra",
      title: "Humeur et détails CRA",
      icon: "people-outline",
    },
    {
      path: "looker-onebusiness",
      title: "Dashboard OneBusiness",
      icon: "stats-chart-outline",
    },
    {
      path: "looker-onestaffing",
      title: "Dashboard OneStaffing",
      icon: "stats-chart-outline",
    }
  ];

  showSideBar: boolean = false;

  public isLoggedIn: boolean = false;
  public isLoginPage: boolean = false;
  private loginSubscription: Subscription = new Subscription();

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private cookieService: CookieService,
    private alertController: AlertController,
    private eventService: EventsService,
    private authService: AuthService,
    private router: Router,
    private location: Location,
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Check if the current route is the login page
        this.isLoginPage = (event.url.split("/")[1] == "" || event.url.split("/")[1] == "sign-in");
      }
    });

    this.authService.initLogin();

    this.loginSubscription = this.authService.isLoggedIn.subscribe(status => {
      this.isLoggedIn = status;
    });

    this.eventService.subscribe("cookie::delete", () => {
      this.showCookiesConsent();
    });
    this.initializeApp();
    router.events.subscribe((val) => {
      if (location.path() == "" || location.path() == "/sign-in") {
        this.showSideBar = false;
      } else {
        this.showSideBar = true;
      }
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.showCookiesConsent();

      this.eventService.subscribe("load::path", () => {
        this.authService.getAuthorizedPath().subscribe(
          (authorizedPath) => {
            var tempRoute = cloneDeep(this.routes);
            // Iterate in routes and check if the user is authorized
            tempRoute.forEach((route) => {
              // Delete the route if the user is not authorized
              if (authorizedPath["data"].indexOf(route.path) == -1) {
                // Iterate in this.routes to find the route to delete
                this.routes.forEach((element, index) => {
                  if (element.path == route.path) {
                    this.routes.splice(index, 1);
                  }
                });
              }
            });
          },
          async (error) => { }
        );
      });
    });
  }

  async showCookiesConsent() {
    if (!this.cookieService.check("cookie-consent")) {
      const alert = await this.alertController.create({
        message:
          'Ce site web utilise des cookies afin de vous offrir les informations les plus pertinentes. \nVeuillez accepter les cookies pour une performance optimale <br/> <br> <ion-icon name="bulb">  </ion-icon> Les cookies permettront de sauvegarder le(s) filtres apliqué(s)',
        cssClass: "cookie-consent",
        backdropDismiss: false,
        buttons: [
          {
            text: "Oui, j'accepte les cookies",
            cssClass: "yes-button",
            handler: () => {
              this.cookieService.set(
                "cookie-consent",
                "yes",
                365,
                undefined,
                undefined,
                true
              );
              this.eventService.publish("cookie::consent", "yes");
              cookieConsent = "yes";
            },
          },
          {
            text: "Non, je n'accepte pas les cookies",
            cssClass: "no-button",
            handler: () => {
              this.cookieService.set(
                "cookie-consent",
                "no",
                365,
                undefined,
                undefined,
                true
              );
              this.eventService.publish("cookie::consent", "no");
              cookieConsent = "no";
            },
          },
        ],
      });
      await alert.present();
    } else {
      cookieConsent = this.cookieService.get("cookie-consent");
    }
  }
}

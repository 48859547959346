import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AuthService } from '../auth/auth.service';


@Injectable()
export class ProfilePageResolver implements Resolve<any> {
  constructor(private authService: AuthService) { }

  resolve() {
    const user: any = [{ 'email': '', 'picture': '' }];
    user.email = this.authService.getUserEmail();
    user.photo = this.authService.getUserPicture()
    return user;
  }
}

import { Component } from "@angular/core";
import { ITooltipAngularComp } from "ag-grid-angular-legacy";
import { ITooltipParams } from "ag-grid-community";

@Component({
  selector: "app-tooltip",
  templateUrl: "./tooltip.component.html",
  styleUrls: ["./tooltip.component.scss"],
})
export class TooltipComponent implements ITooltipAngularComp {
  private params!: { color: string } & ITooltipParams;
  public color!: string;
  public taae: number;
  public tace: number;
  public page: string;
  public effectif: number;
  public label: string;
  public collab: string;

  public agInit(params: { color: string } & ITooltipParams): void {
    this.params = params;
    this.collab = params.data["Collaborateur"];
    this.page = params["page"];

    if (this.page === "staffing-jours" && this.collab != null)
      this.calculate_TAAE_TACE(params);
    else if (this.page === "staffing-tace" && this.collab != null)
      this.calculate_TAAE(params);
    else if (this.page === "taae-tace" || this.page === "taux-grade")
      this.calculate_effectif(params);
    else if (this.page === "feuille-staffing" || this.page === "feuille-dispos")
      this.get_feuille_staffing_dispos_label(params);
    else if (this.page === "feuille-tjm") this.get_feuille_tjm_label(params);
    else if (this.page === "reporting-velvet")
      this.get_vision_gloable_label(params);

    this.color = this.params.color || "white";
  }

  private calculate_TAAE_TACE(
    params: { color: string } & ITooltipParams
  ): void {
    var tace_staffing = 0;
    var taae_staffing = 0;
    var billable_time =
      params.data["billable_time-" + params["colDef"]["field"]];
    var conge = params.data["conges-" + params["colDef"]["field"]];
    var absences = params.data["absences-" + params["colDef"]["field"]];

    tace_staffing = Math.round((params.value * 100) / (billable_time - conge));

    taae_staffing = Math.round(
      (params.value * 100) / (billable_time - absences)
    );

    if (billable_time - conge == 0)
      tace_staffing = Math.round(
        ((params.value + billable_time) * 100) / billable_time
      );
    if (billable_time - absences == 0)
      taae_staffing = Math.round(
        ((params.value + billable_time) * 100) / billable_time
      );
    if (isNaN(taae_staffing)) taae_staffing = 0;
    if (isNaN(tace_staffing)) tace_staffing = 0;
    this.taae = taae_staffing;
    this.tace = tace_staffing;
  }

  private calculate_TAAE(params: { color: string } & ITooltipParams): void {
    var tace_staffing = 0;
    var taae_staffing = 0;
    var billable_time =
      params.data["billable_time-" + params["colDef"]["field"]];
    var conge = params.data["conges-" + params["colDef"]["field"]];
    var absences = params.data["absences-" + params["colDef"]["field"]];

    tace_staffing = Math.round(
      (params.data["staffing-" + params["colDef"]["field"]] * 100) /
        (billable_time - conge)
    );

    taae_staffing = Math.round(
      (params.data["staffing-" + params["colDef"]["field"]] * 100) /
        (billable_time - absences)
    );

    if (billable_time - conge == 0)
      tace_staffing = Math.round(
        ((params.data["staffing-" + params["colDef"]["field"]] +
          billable_time) *
          100) /
          billable_time
      );
    if (billable_time - absences == 0)
      taae_staffing = Math.round(
        ((params.data["staffing-" + params["colDef"]["field"]] +
          billable_time) *
          100) /
          billable_time
      );
    if (isNaN(taae_staffing)) taae_staffing = 0;
    if (isNaN(tace_staffing)) tace_staffing = 0;
    this.taae = taae_staffing;
    this.tace = tace_staffing;
  }

  private calculate_effectif(params: { color: string } & ITooltipParams): void {
    this.effectif = parseInt(
      params.data["effectif-" + params["colDef"]["field"]]
    );
  }

  private get_feuille_staffing_dispos_label(
    params: { color: string } & ITooltipParams
  ): void {
    const bu = params.data["bu"];
    const pole = params.data["pole"];
    const field = params["colDef"]["field"];
    const fieldValue = params.data[field].toString();

    if (!bu) {
      this.label = "Jours ouvrables";
    } else if (!pole && fieldValue.includes("%")) {
      this.label = "TACE";
    } else if (!pole && !fieldValue.includes("%")) {
      this.label = "Somme";
    }
  }

  private get_feuille_tjm_label(
    params: { color: string } & ITooltipParams
  ): void {
    if (params.data["bu"] == "" || params.data["bu"] == null)
      this.label = "Jours ouvrables";
    else if (params.data["pole"] == "" || params.data["pole"] == null) {
      if (params.data[params["colDef"]["field"]].toString().includes("%"))
        this.label = "TACE";
      else this.label = "TJM";
    }
  }

  private get_vision_gloable_label(
    params: { color: string } & ITooltipParams
  ): void {
    if (params.data["data"].includes("pôle")) this.label = params.data["data"];
    else
      switch (params.data["data"]) {
        case "Chiffre d'affaires réalisé":
          this.label =
            "TJM x staffing (normal + Astreinte hors Velvet Consulting)";
        case "Marge reconstituée":
          this.label =
            "(TJM x staffing (normal + Astreinte hors Velvet Consulting)) - (CJM x (Jour ouvrés - CP - RTT - Congés sans solde - Absence Temps Partiel - Congés exceptionnels - RTT Employeur))";
          break;
        case "Rentabilité moyenne":
          this.label = "Marge reconstituée / Chiffre d'affaires réalisé";
          break;
        case "Revenu/collab":
          this.label = "Chiffre d'affaires réalisé / Effectif en temps réel";
          break;
        case "Marge/collab":
          this.label = "Marge reconstituée / Effectif en temps réel";
          break;
        case "TACE en temps réel":
          this.label =
            "(staffing / (Jour ouvrés - CP - RTT Salarié - Congés sans solde - Absence Temps Partiel - Congés exceptionnels - RTT Employeur)) x 100";
          break;
        case "TAAE en temps réel":
          this.label = "(staffing / (Jour ouvrés - TOUTE LES ABSENCES )) x 100";
          break;
        default:
          break;
      }
  }
}
